import React from "react"
import styles from './index.module.css'

import MainLayout from "../layouts/MainLayout"
import SEO from "../components/seo"

const AboutPage = () => {
  return (
    <MainLayout>
      <SEO title="About Crowdlever - Recent order notifications for your online store" />
      <div className={styles.section}>
        <center><h1>Why you should use social proof</h1></center>
        <p>90% of consumers say buying decisions are influenced by online reviews and ratings.</p>
        <p>These reviews and rating are a form of passive social proof, but we think the next step is active social proof technology</p>
        <p>By surfacing existing customer activity, like signups, ticket sales, purchases, subscription renewals, or anything else through smart notifications, you can boost your website's social proof.</p>
        <p>Easily leverage your existing customer activity, to turn new visitors into new customers.</p>
      </div>
    </MainLayout>
  )
}

export default AboutPage